/*****************************************
 * Monj - User Generate Content (UGC)
 * Created by Jared Beckerman
 *
 */

// Config
var namespace = 'data-monjugc';
var previousResults = false;
var canUpdate = false;   // Infinite scrolling flag - Will only update if canUpdate is true


/************************
 * Vanilla Javascript Ajax Implimentation - Thanks to Petah from Stack Overflow for this solution and preventing any outside dependencies in this script
 * https://stackoverflow.com/questions/8567114/how-to-make-an-ajax-call-without-jquery
 */

var ugc_ajax = {};
ugc_ajax.x = function () {
    if (typeof XMLHttpRequest !== 'undefined') {
        return new XMLHttpRequest();
    }
    var versions = [
        "MSXML2.XmlHttp.6.0",
        "MSXML2.XmlHttp.5.0",
        "MSXML2.XmlHttp.4.0",
        "MSXML2.XmlHttp.3.0",
        "MSXML2.XmlHttp.2.0",
        "Microsoft.XmlHttp"
    ];

    var xhr;
    for (var i = 0; i < versions.length; i++) {
        try {
            xhr = new ActiveXObject(versions[i]);
            break;
        } catch (e) {
        }
    }
    return xhr;
};

ugc_ajax.send = function (url, callback, method, data, async) {
    if (async === undefined) {
        async = true;
    }
    var x = ugc_ajax.x();
    x.open(method, url, async);
    x.onreadystatechange = function () {
        if (x.readyState == 4) {
            callback(x.responseText)
        }
    };
    if (method == 'POST') {
        x.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    }
    if (method == 'PUT') {
        x.setRequestHeader('Content-type', 'application/json');
    }
    x.send(data)
};

ugc_ajax.get = function (url, data, callback, async) {
    var query = [];
    for (var key in data) {
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
    ugc_ajax.send(url + (query.length ? '?' + query.join('&') : ''), callback, 'GET', null, async)
};

ugc_ajax.post = function (url, data, callback, async) {
    var query = [];
    for (var key in data) {
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
    ugc_ajax.send(url, callback, 'POST', query.join('&'), async)
};

ugc_ajax.delete = function (url, data, callback, async) {
    var query = [];
    for (var key in data) {
        query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
    ugc_ajax.send(url, callback, 'DELETE', query.join('&'), async)
};

ugc_ajax.put = function (url, data, callback, async) {
    ugc_ajax.send(url, callback, 'PUT', JSON.stringify(data), async)
};

/**
 * End Vanilla Javascript Ajax
 ************************/



/************************
 * BEGIN API CALLS
 */

var ugc_api = {};

/**
 * Get content from monjApi
 * @param contentType -  Valid content types: 'meals', 'communities', 'challenges' or 'powerups'
 * @param id
 * @param pageSize
 * @param page
 * @returns {Promise}
 */
ugc_api.getContent = function(contentType, id, pageSize, page, userId, monjApiKey, monjURL) {
    // Always return object as a promise to asynchronously load data
    if(previousResults) {
        return new Promise(function (resolve, reject) {
            ugc_ajax.get(monjURL + '/ugc/' + contentType + '/' + id, {
                userId: userId,
                pageSize: pageSize,
                page: page,
                apiKey: monjApiKey
            }, function (data) {
                previousResults = data;
                resolve(JSON.parse(data));
            });
        });
    }
};

/**
 * Get featured content from monjApi
 * @param contentType -  Valid content types: 'meals', 'communities', 'challenges' or 'powerups'
 * @param id
 * @param pageSize
 * @param page
 * @returns {Promise}
 */
ugc_api.getFeaturedContent = function(contentType, id, pageSize, page, userId, monjApiKey, monjURL) {
    // Always return object as a promise to asynchronously load data
    if(previousResults) {
        return new Promise(function (resolve, reject) {
            ugc_ajax.get(monjURL + '/ugc/' + contentType + '/' + id + '/featured', {
                userId: userId,
                pageSize: pageSize,
                page: page,
                apiKey: monjApiKey
            }, function (data) {
                previousResults = data;
                resolve(JSON.parse(data));
            });
        });
    }
};

/**
 * Like an item
 * @param userId -  used to get the userId
 * @param id
 * @returns {Promise}
 */
ugc_api.likeItem = function(userId, id, monjApiKey, monjURL) {
    // Always return object as a promise to asynchronously load data
    return new Promise(function (resolve, reject) {
        ugc_ajax.put(monjURL + '/ugc/likes/' + id + '?userId=' + userId + '&apiKey=' + monjApiKey, {

        }, function (data) {
            resolve(JSON.parse(data));
        });
    });
};

/**
 * Dislike an item
 * @param userId -  used to get the userId
 * @param id
 * @returns {Promise}
 */
ugc_api.dislikeItem = function(userId, id, monjApiKey, monjURL) {
    // Always return object as a promise to asynchronously load data
    return new Promise(function (resolve, reject) {
        ugc_ajax.delete(monjURL + '/ugc/likes/' + id + '?userId=' + userId + '&apiKey=' + monjApiKey, {

        }, function (data) {
            resolve(JSON.parse(data));
        });
    });
};

ugc_api.getLikes = function(id, monjApiKey, monjURL) {
    return new Promise(function (resolve, reject) {
        ugc_ajax.get(monjURL + '/ugc/likes/' + id, {
            apiKey: monjApiKey
        }, function (data) {
            resolve(JSON.parse(data));
        });
    });
};

ugc_api.addItems = function(elem, options, data) {
    // If carousel is enabled, create a parent div to wrap around carousel for touch and mouse navigation controls
    if(options.carousel) {
        var block = document.createElement("div");
        block.className = options.subClassName + 'carousel-wrapper';
        elem.appendChild(block);

        if(options.navigationArrows) {
            // Create Carousel Arrows
            var arrowContainer = document.createElement("div");
            arrowContainer.classList.add(options.subClassName + 'arrow-container');

            var leftArrow = document.createElement("div");
            leftArrow.classList.add(options.subClassName + 'arrow');
            leftArrow.classList.add('left');
            leftArrow.onclick = function () {
                ugc_carousel.snapToPosition(elem.parentElement, selectedItemIndex - 1);
            };

            var rightArrow = document.createElement("div");
            rightArrow.classList.add(options.subClassName + 'arrow');
            rightArrow.classList.add('right');
            rightArrow.onclick = function () {
                ugc_carousel.snapToPosition(elem.parentElement, selectedItemIndex + 1);
            };

            arrowContainer.appendChild(leftArrow);
            arrowContainer.appendChild(rightArrow);

            elem.appendChild(arrowContainer);
        }

        elem = block;
    }

    // Remove old loading item if one exists
    ugc_item.removeLazyLoadItem(options.subClassName);

    for(var x = 0; x < data.length; x++) {
        ugc_item.addItem(elem, data[x], options.subClassName, options.monjApiKey, options.monjUrl, options.userId);
    }

    if(!options.carousel && !options.disableInfiniteScroll && (parseInt(options.pageSize) === data.length)) {
        // After initialization, test scroll position to lazy-load and infinite scroll through items
        ugc_item.addLazyLoadItem(elem, options.subClassName);
    }

    // If masonry has been included and a grid item has been named, intialize it here
    var masonryElem = document.getElementsByClassName(options.masonryGrid);
    if (window.Masonry && !options.carousel && masonryElem.length > 0) {
        setTimeout(function () {
            var masonryElem = document.querySelector('.' + options.masonryGrid);
            new Masonry(masonryElem, {
                itemSelector: '.' + options.masonryGridItem
            });
        });
    }
};

ugc_api.createContentGrid = function(elem, options) {
    if(canUpdate) {
        canUpdate = false;
        ugc_api.getContent(options.contentType, options.id, options.pageSize, options.pageStart, options.userId, options.monjApiKey, options.monjUrl).then(function(data) {
            if(data.length > 0) {
                setTimeout(function() {
                    canUpdate = true;
                }, 400);
            }
            ugc_api.addItems(elem, options, data);

            ugc_item.initFeatures(options, elem);

            // Remove loading animation block
            var loading = document.getElementById(options.subClassName + 'loading');
            if(loading) {
                loading.classList.remove('active');
            }
        });
    }
};

ugc_api.createFeaturedContentGrid = function(elem, options) {
    if(canUpdate) {
        canUpdate = false;
        ugc_api.getFeaturedContent(options.contentType, options.id, options.pageSize, options.pageStart, options.userId, options.monjApiKey, options.monjUrl).then(function (data) {
            if(data.length > 0) {
                setTimeout(function() {
                    canUpdate = true;
                }, 400);
            }

            ugc_api.addItems(elem, options, data);

            ugc_item.initFeatures(options, elem);

            // Remove loading animation block
            var loading = document.getElementById(options.subClassName + 'loading');
            if(loading) {
                loading.classList.remove('active');
            }
        });
    }
};

/**
 * END API CALLS
 ************************/

// Calculate time ago - Thanks to stack overflow for this code - Edited slightly by JBB
// https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time-eg-2-seconds-ago-one-week-ago-etc-best
function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago';
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';
    }

    else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';
    }

    else {
        return Math.round(elapsed/msPerYear ) + ' years ago';
    }
}


/************************
 * Build DOM elements
 */

var ugc_item = {};

ugc_item.closePopup = function(elem, content, subClassName) {
    var modalBackground = document.getElementById(subClassName + 'modal-background');
    var popupObject = document.getElementById(subClassName + 'popup');
    modalBackground.remove();
    popupObject.remove();
};

ugc_item.imageExists = function(image_url) {
    if(image_url) {
        return new Promise(function (resolve, reject) {

            var http = new XMLHttpRequest();

            http.open('HEAD', image_url, true);
            http.send();

            resolve(http.status != 404);
        });
    }

    return new Promise(function (resolve, reject) {
        reject('No image_url found.');
    });
};

ugc_item.playButton = function() {
    // Build SVG play button
    var svg = document.createElement("svg");
    svg.classList.add("fp-play-rounded-fill");
    svg.setAttribute("viewBox", "0 0 100 100");

    // Definitions
    var defs = document.createElement("defs");
    var style = document.createElement("style");

    var path = document.createElement("path");
    path.setAttribute("d", "M49.9217-.078a50,50,0,1,0,50,50A50.0564,50.0564,0,0,0,49.9217-.078Z");

    var path2 = document.createElement("path");
    path2.setAttribute("d", "M35.942,35.2323c0-4.7289,3.3506-6.6637,7.446-4.2971L68.83,45.6235c4.0956,2.364,4.0956,6.2319,0,8.5977L43.388,68.91c-4.0954,2.364-7.446.43-7.446-4.2979Z");

    svg.appendChild(path);
    svg.appendChild(path2);

    return svg;
};

// Creates and opens a new popup. On close, destroy the popup DOM elements
ugc_item.openPopup = function(elem, content, subClassName, monjApiKey, monjURL, userId) {
    // Create the darkened modal background and make it clickable to close the popup
    var modalBackground = document.createElement("div");
    modalBackground.id = subClassName + 'modal-background';
    modalBackground.onclick = function() {
        ugc_item.closePopup(elem, content, subClassName);
    };
    document.body.appendChild(modalBackground);
    var popup = document.createElement("div");
    popup.id = subClassName + 'popup';
    document.body.appendChild(popup);

    var popupObject = document.getElementById(subClassName + 'popup');

    if(content.mediaType == 'Video') {
        // Create the video block
        var videoDiv = document.createElement("div");
        videoDiv.classList.add(subClassName + 'video-container');
        var imageBlock = document.createElement("video");
        imageBlock.className = subClassName + 'popup-video';
        imageBlock.setAttribute("poster", content.mediaUrl);
        imageBlock.onclick = function() {
            imageBlock.play();
        };

        var videoSource = document.createElement("source");
        videoSource.setAttribute("src", content.videoUrl);
        videoSource.setAttribute("type", "video/mp4");
        imageBlock.appendChild(videoSource);

        var playButton = document.createElement("div");
        playButton.classList.add(subClassName + 'play-button');
        playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <defs><style>.a{fill:#000;opacity:0.65;}.b{fill:#fff;opacity:1.0;}</style> </defs><title>play-rounded-fill</title><path class="b" d="M35.942,35.2323c0-4.7289,3.3506-6.6637,7.446-4.2971L68.83,45.6235c4.0956,2.364,4.0956,6.2319,0,8.5977L43.388,68.91c-4.0954,2.364-7.446.43-7.446-4.2979Z"></path></svg>';
        playButton.onclick = function() {
            imageBlock.play();
            playButton.style = "opacity: 0;";
        };
        videoDiv.appendChild(playButton);

        if(userId) {
            var likeElem = ugc_item.likedElement(elem, content, subClassName, monjApiKey, monjURL, userId, true);
            imageBlock.appendChild(likeElem);
        }

        videoDiv.appendChild(imageBlock);
        popupObject.appendChild(videoDiv);
    } else {
        // Create the image block
        var imageBlock = document.createElement("div");
        imageBlock.className = subClassName + 'popup-image';
        ugc_item.imageExists(content.mediaUrl).then(function(data) {
            imageBlock.setAttribute("style", "background-image: url('" + content.mediaUrl + "');");
        });

        if(userId) {
            var likeElem = ugc_item.likedElement(elem, content, subClassName, monjApiKey, monjURL, userId, true);
            imageBlock.appendChild(likeElem);
        }

        popupObject.appendChild(imageBlock);
    }

    // Create the close button
    var closeButton = document.createElement("div");
    closeButton.className = subClassName + 'close-button';
    closeButton.onclick = function() {
        ugc_item.closePopup(elem, content, subClassName);
    };
    //var text = document.createTextNode('x');
    //closeButton.appendChild(text);
    popupObject.appendChild(closeButton);

    // Create the information block
    var informationBlock = document.createElement("div");
    informationBlock.className = subClassName + 'information-container';

    // User Profile Image
    var profileImage = document.createElement("div");
    profileImage.className = subClassName + 'profile-image';
    ugc_item.imageExists(content.userProfilePictureUrl).then(function(data) {
        profileImage.setAttribute("style", "background-image: url('" + content.userProfilePictureUrl + "');");
    });
    informationBlock.appendChild(profileImage);


    // User Profile name
    var profileName = document.createElement("h1");
    var name = document.createTextNode(content.userRealName);
    profileName.appendChild(name);
    informationBlock.appendChild(profileName);

    // Created date
    var createdDate = document.createElement("h3");
    var date = document.createTextNode(timeDifference(new Date().getTime(), new Date(content.sourceCreatedTime).getTime()));
    createdDate.appendChild(date);
    informationBlock.appendChild(createdDate);

    // Horizontal Rule
    var hr = document.createElement("hr");
    informationBlock.appendChild(hr);

    // Add full text content to block if the text exists
    if(content.text) {
        var textBlock = document.createElement("p");
        var text = document.createTextNode(content.text);
        textBlock.appendChild(text);
        informationBlock.appendChild(textBlock);
    }

    // Add all elements to the information block
    popupObject.appendChild(informationBlock);
};

ugc_item.likedElement = function(elem, content, subClassName, monjApiKey, monjURL, userId, isActive) {
    //api.getLikes(content.id, monjApiKey, monjURL).then(function (data) {
    var likeElem = document.createElement("div");
    likeElem.className = subClassName + "like";
    if(content.isLikedByUser) {
        likeElem.classList.add('liked');
    }
    if(isActive) {
        likeElem.classList.add('active');
    }
    var likeNumber = document.createElement("p");
    var text = document.createTextNode(content.likedCount);
    likeNumber.appendChild(text);

    likeElem.appendChild(likeNumber);

    // Use clickable variable to limit the amount of clicks per second a user is capable of querying the server
    var clickable = true;

    likeElem.onclick = function (evt) {
        if(content.isLikedByUser) {
            if(clickable) {
                clickable = false;
                ugc_api.dislikeItem(userId, content.id, monjApiKey, monjURL).then(function (newNumber) {
                    content.isLikedByUser = false;
                    likeElem.classList.remove('liked');
                    content.likedCount--;
                    likeNumber.innerHTML = content.likedCount;
                    setTimeout(function () {
                        clickable = true;
                    }, 1000);
                });
            }
        } else {
            if(clickable) {
                clickable = false;
                ugc_api.likeItem(userId, content.id, monjApiKey, monjURL).then(function (newNumber) {
                    content.isLikedByUser = true;
                    likeElem.classList.remove('liked');
                    likeElem.classList.add('liked');
                    content.likedCount++;
                    likeNumber.innerHTML = content.likedCount;
                    setTimeout(function () {
                        clickable = true;
                    }, 1000);
                });
            }
        }
        evt.preventDefault();
    };

    return likeElem;
};

ugc_item.addItem = function(elem, content, subClassName, monjApiKey, monjURL, userId) {
    var block = document.createElement("div");
    block.className = subClassName + 'item grid-item';
    /*block.onclick = function() {
     item.openPopup(elem, content, subClassName);
     };*/

    // Create item background image
    var image = document.createElement('div');
    image.className = subClassName + 'image';

    ugc_item.imageExists(content.mediaUrl).then(function(data) {
        image.setAttribute("style", "background-image: url('" + content.mediaUrl + "');");
    });

    if(content.mediaType == "Video") {
        var playButton = document.createElement("div");
        playButton.classList.add(subClassName + 'play-button');
        playButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <defs><style>.a{fill:#000;opacity:0.65;}.b{fill:#fff;opacity:1.0;}</style> </defs><title>play-rounded-fill</title><path class="b" d="M35.942,35.2323c0-4.7289,3.3506-6.6637,7.446-4.2971L68.83,45.6235c4.0956,2.364,4.0956,6.2319,0,8.5977L43.388,68.91c-4.0954,2.364-7.446.43-7.446-4.2979Z"></path></svg>';
        image.appendChild(playButton);
    }

    // Add text block that appears on hover over image
    var textElem = document.createElement("div");
    var p = document.createElement("p");
    textElem.className = subClassName + 'text';
    if(content.text) {
        var text = document.createTextNode(content.text);
        p.appendChild(text);
    }

    textElem.onclick = function() {
        if(!textElem.classList.contains('no-click')) {
            ugc_item.openPopup(elem, content, subClassName, monjApiKey, monjURL, userId);
        }
    };

    textElem.appendChild(p);
    image.appendChild(textElem);

    // Create "like" block
    if(userId) {
        var likeElem = ugc_item.likedElement(elem, content, subClassName, monjApiKey, monjURL, userId);
        image.appendChild(likeElem);
    }

    block.appendChild(image);

    // Add profile image to featured views
    // User Profile Image
    var profileImage = document.createElement("div");
    profileImage.className = subClassName + 'profile-image';
    profileImage.classList.add('featured');

    ugc_item.imageExists(content.userProfilePictureUrl).then(function(data) {
        profileImage.setAttribute("style", "background-image: url('" + content.userProfilePictureUrl + "');");
    });

    // Add moderators comment for carousel views
    if(content.moderatorComment) {
        var modComment = document.createElement("div");
        modComment.className = subClassName + 'moderators-comment';
        modComment.appendChild(profileImage);
        var modName = "Monj says:";
        if(content.moderatorName) {
            modName = content.moderatorName;
        }
        var nameContainer = document.createElement("h5");
        var name = document.createTextNode(modName);
        nameContainer.appendChild(name);
        modComment.appendChild(nameContainer);
        var textContainer = document.createElement("p");
        var text = document.createTextNode(content.moderatorComment);
        textContainer.appendChild(text);
        modComment.appendChild(textContainer);
        block.appendChild(modComment);
    } else {
        var modComment = document.createElement("div");
        modComment.className = subClassName + 'moderators-comment';
        modComment.appendChild(profileImage);
        var nameContainer = document.createElement("h5");
        var name = document.createTextNode(content.userRealName);
        nameContainer.appendChild(name);
        modComment.appendChild(nameContainer);
        var textContainer = document.createElement("p");
        if(content.text) {
            var text = document.createTextNode(content.text);
            textContainer.appendChild(text);
        }
        modComment.appendChild(textContainer);
        block.appendChild(modComment);
    }

    elem.appendChild(block);
};

ugc_item.addLazyLoadItem = function(elem, subClassName) {
    var block = document.createElement("div");
    block.className = subClassName + 'item grid-item';
    block.id = subClassName + 'loading';

    var spinner = document.createElement("div");
    spinner.className = subClassName + 'spinner';

    var bounce = document.createElement("div");
    bounce.className = subClassName + 'bounce1';
    spinner.appendChild(bounce);

    var bounce = document.createElement("div");
    bounce.className = subClassName + 'bounce2';
    spinner.appendChild(bounce);

    var bounce = document.createElement("div");
    bounce.className = subClassName + 'bounce3';
    spinner.appendChild(bounce);

    block.appendChild(spinner);
    elem.appendChild(block);
};

ugc_item.removeLazyLoadItem = function(subClassName) {
    var loading = document.getElementById(subClassName + 'loading');
    if(loading) {
        loading.remove();
    }
};

// Setup as an item function to be run after the promise has been returned and DOM elements built. This function needs the dom elements to exist for the features to work.
ugc_item.initFeatures = function(options, elem) {
    // Lazy Loading Pagination - Only load methods if we are not in carousel mode and infinite scrolling has not been disabled. -- Don't load if there are multiple instances (for now)
    if (!options.carousel && !options.disableInfiniteScroll) {
        function isInViewport(element) {
            var rect = element.getBoundingClientRect();
            var html = document.documentElement;
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || html.clientHeight) &&
                rect.right <= (window.innerWidth || html.clientWidth)
            );
        }

        function updateData() {
            var loading = document.getElementById(options.subClassName + 'loading');
            // Remove loading animation block

            if (loading) {
                if(loading.classList.contains('active')) {
                    loading.classList.remove('active');
                }
                if (isInViewport(loading)) {
                    // Display loading animation block
                    loading.classList.add('active');
                    // Load next set of items
                    options.pageStart++;
                    if (options.featured) {
                        // Create featured grid
                        ugc_api.createFeaturedContentGrid(elem, options);
                    } else {
                        // Create grid
                        ugc_api.createContentGrid(elem, options);
                    }
                }
            }
        }

        // Call once on load initially to check if more items should be loaded into view
        updateData();

        window.onscroll = function () {
            updateData();
        };
    }

    if (options.carousel) {
        // If carousel has been selected, set the parent class to structure the elements as a carousel
        elem.className += " " + options.subClassName + "carousel";
        ugc_carousel.init(elem);
        ugc_carousel.touch(elem);
        // Draw arrows
    }
};

/**
 * End Build Dom Elements
 ************************/

/************************
 * Carousel Tools
 */

var ugc_carousel = {};
var selectedItemIndex = 0;

ugc_carousel.init = function(elem) {
    ugc_carousel.updatePosition(elem);
    ugc_carousel.snapToPosition(elem);
    // set an event listener for window width updates
    window.addEventListener('resize', function () {
        ugc_carousel.snapToPosition(elem);
        if(elem.childNodes[0].offsetWidth > 768) {
            elem.childNodes[0].setAttribute("style", "left:0px;");
        }
    });
};

ugc_carousel.updatePosition = function(elem) {
    var items = elem.childNodes[0].childNodes;

    for(var x = 0; x < items.length; x++) {
        items[x].classList.remove("selected");
        if(x == selectedItemIndex) {
            items[x].className += ' selected';
        }
    }
};

var mouseDown = false;
var carouselMoved = false;
var mouseX = 0;
var stopPropogation = false;
var startPosition = 0;
ugc_carousel.touch = function(elem) {
    elem.addEventListener("touchstart", handleStart, false);
    elem.addEventListener("touchend", handleEnd, false);
    elem.addEventListener("touchcancel", handleEnd, false);
    elem.addEventListener("touchmove", handleMove, false);
    elem.addEventListener("mousedown", handleStart, false);
    elem.addEventListener("mousemove", handleMove, false);
    elem.addEventListener("mouseup", handleEnd, false);
    elem.addEventListener("mouseleave", handleEnd, false);
};

ugc_carousel.snapToPosition = function(elem, index) {
    if(elem.childNodes[0].childNodes[0]) {
        var itemWidth = elem.childNodes[0].childNodes[0].offsetWidth + 8;
        var currentPosition = elem.childNodes[0].offsetLeft;

        var leftPadding = 0;
        if (elem.childNodes[0].offsetWidth < 769) {
            leftPadding = (elem.childNodes[0].offsetWidth - itemWidth) / 2;
        }

        var length = elem.childNodes[0].childNodes.length - 1;

        if (index !== undefined) {
            selectedItemIndex = Math.max(Math.min(index, length), 0);
        } else {
            selectedItemIndex = Math.abs(Math.round(( currentPosition - leftPadding ) / itemWidth));
        }

        updateArrows(elem, selectedItemIndex, length);

        ugc_carousel.updatePosition(elem);

        if (elem.childNodes[0].offsetWidth < 769) {
            startPosition = (Math.abs(itemWidth * selectedItemIndex) * -1) + leftPadding;
        } else {
            startPosition = Math.abs(itemWidth * selectedItemIndex) * -1;
        }

        elem.childNodes[0].setAttribute("style", "left:" + startPosition + "px;");
    }
};

function updateArrows(elem, index, length) {
    var left = elem.getElementsByClassName('left')[0];
    if(left) {
        if (index <= 0) {
            left.classList.add('hide');
        } else {
            left.classList.remove('hide');
        }
    }

    var right = elem.getElementsByClassName('right')[0];
    if(right) {
        if (index >= length) {
            right.classList.add('hide');
        } else {
            right.classList.remove('hide');
        }
    }
}

function handleStart(evt) {
    mouseDown = true;
    stopPropogation = true;
    if(evt.changedTouches) {
        evt.clientX = evt.changedTouches[0].clientX;
    }
    mouseX = evt.clientX;
}

function handleEnd(evt) {
    mouseDown = false;
    startPosition = this.childNodes[0].offsetLeft;
    this.childNodes[0].style.transition = "left 400ms ease";
    if(carouselMoved) {
        evt.preventDefault();
        ugc_carousel.snapToPosition(this);
        carouselMoved = false;

        var items = this.childNodes[0].childNodes;
        setTimeout(function() {
            // remove no-click class to ugc-text
            for(var x = 0; x < items.length; x++) {
                items[x].childNodes[0].childNodes[0].classList.remove('no-click');
            }
        }, 400);
    }
}

function handleMove(evt) {
    if(evt.changedTouches) {
        evt.clientX = evt.changedTouches[0].clientX;
    }
    if(mouseDown) {
        if(stopPropogation) {
            stopPropogation = false;
            // add no-click class to ugc-text - Only perform this once
            var items = this.childNodes[0].childNodes;
            for (var x = 0; x < items.length; x++) {
                items[x].childNodes[0].childNodes[0].classList.add('no-click');
            }
        }

        carouselMoved = true;
        // Calculate movement relative to start position
        var movementX = evt.clientX + startPosition - mouseX;

        // Set a limit for left and right mouse movement. Once hit, reduce movement speed by 1/4 and snap back to first or last position
        var itemWidth = parseFloat(this.childNodes[0].childNodes[0].offsetWidth) + 8;
        var numberOfItems = this.childNodes[0].childNodes.length;
        var totalWidth = itemWidth * numberOfItems;    // Account for scrollbar

        if(this.childNodes[0].offsetWidth < 769) {
            var leftPadding = (this.childNodes[0].offsetWidth - itemWidth) / 2;
            // Left Extreme
            if(movementX > leftPadding) {
                // Hard Limit
                movementX = leftPadding;
            }

            // Right Extreme
            if(movementX < ( this.childNodes[0].offsetWidth - totalWidth - leftPadding )) {
                // Hard Limit
                movementX = this.childNodes[0].offsetWidth - totalWidth - leftPadding;
            }
        } else {
            // Left Extreme
            if(movementX > 0) {
                // Hard Limit
                movementX = 0;
            }

            // Right Extreme
            if(movementX < ( this.childNodes[0].offsetWidth - totalWidth )) {
                // Hard Limit
                movementX = this.childNodes[0].offsetWidth - totalWidth;
            }
        }

        this.childNodes[0].setAttribute("style", "left:" + movementX + "px;transition:none;");
        evt.preventDefault();
    }
}

/**
 * End Carousel Tools
 ************************/

var monjugc = {};
var elements = [];

monjugc.init = function(options, elem, reload) {
    if(typeof elem == 'string') {
        elem = document.getElementById(elem);
    }

    if(elem) {
        if(!reload) {
            // Store each instance in case the user needs to reload/refresh the content at some point without re-initializing
            var package = {};
            package.options = options;
            package.elem = elem;
            elements.push(package);
        }

        previousResults = true;
        canUpdate = true;

        // Check and set default values if they have not been passed in
        options = {
            animationTime: options.animationTime ? options.animationTime : 400,         // Animation speed (default 400ms)
            waitDuration: options.waitDuration ? options.waitDuration : 3000,           // 3 seconds default wait time between carousel slides
            pageStart: options.pageStart ? options.pageStart : 1,                       // Start on page 1 unless otherwise specified
            pageSize: options.pageSize ? options.pageSize : 10,                         // Default to 10 items per page
            contentType: options.contentType ? options.contentType : 'communities',     // Default to 'communities' if no other content type has been provided
            id: options.id ? options.id : 1,                                            // Default to id 1 unless otherwise specified - ID 1 of communities is the insipiration content
            subClassName: options.subClassName ? options.subClassName : 'ugc-',         // Classname appended to the beginning of our classes
            featured: options.featured ? options.featured : false,                      // Flag to determine whether or not to only display featured items
            carousel: options.carousel ? options.carousel : false,                      // Flag to determine whether or not to display content in a carousel or infinite scrolling list
            userId: options.userId ? options.userId : false,                             // Pass in an userId to enable the "likes" functionality
            navigationArrows: options.navigationArrows ? options.navigationArrows : false,                   // Pass in an userId to enable the "likes" functionality
            masonryGrid: options.masonryGrid ? options.masonryGrid : 'grid',                                // Default masonry class name for the grid parent
            masonryGridItem: options.masonryGridItem ? options.masonryGridItem : 'grid-item',               // Default masonry class name for the grid items
            disableInfiniteScroll: options.disableInfiniteScroll ? options.disableInfiniteScroll : false,   // Disable infinite scroll as default
            monjApiKey: options.monjApiKey ? options.monjApiKey : '1',                                      // Set default monjApiKey to dev
            monjUrl: options.monjUrl ? options.monjUrl : 'https://apidev.monj.com/v1'                       // Sets default monjUrl to dev
        };

        if (options.featured) {
            // Create featured grid
            ugc_api.createFeaturedContentGrid(elem, options);
        } else {
            // Create grid
            ugc_api.createContentGrid(elem, options);
        }
    }
};

// This method will reload/refresh the content of all instances currently running
monjugc.reload = function() {
    for(var x = 0; x < elements.length; x++) {
        // Remove old instance and reload new one
        elements[x].elem.innerHTML = '';
        monjugc.init(elements[x].options, elements[x].elem, true);
    }
};

document.addEventListener("DOMContentLoaded", function() {
    // Select the elements containing the data-monjugc attribute
    var elem = document.querySelectorAll('[' + namespace + ']');
    // Check to see if the element exists before continuing
    if(elem) {
        // Loop through elements on the page to allow for multiple instances
        for (var i = 0; i < elem.length; i++) {
            var options = elem[i].getAttribute(namespace);
            if (options) {
                // Collect options if there are any
                options = JSON.parse(JSON.stringify(eval("(" + options + ")")));
            }

            monjugc.init(options, elem[i]);
        }
    }
});